import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { Container, Row, Col } from 'react-bootstrap';

import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//Load Styles
import styles from '../../styles/components/common/Header.module.scss'
import variables from '../../styles/variables.module.scss';

// import Navigation from './navbar';
import Navigation from './navbar_collapse';

// Import Hard Coded/Configured Data
const staticConfiguration = require(`../../config/header${process.env.NEXT_PUBLIC_STATIC_CONTENT_TYPE}.json`);
import getConfig from 'next/config'
//i18n
import { useTranslation } from 'react-i18next';
// Using Global Redux State
import { useSelector, useDispatch } from 'react-redux';
import { fetchWebsiteSettings } from '../../store/websiteSettingsSlice';



const TopHeaderWrapper = styled.div`
    background-color: ${(props)=> props.backgroundColor ? props.backgroundColor : 'red'};
    color: ${(props)=> props.textColor ? props.textColor : 'red'};
    padding: ${(props)=>props.padding};
`;
// The Wrapper is used to overwrite standard styles via Backend
//i.e. padding: 100px
const HeaderWrapper = styled.div`
    background: ${(props)=> `url('${props.backgroundImage}')` || `url(/assets/images/footer_bg.png)`};
    background-color: ${(props)=> props.backgroundColor ? props.backgroundColor : 'red'};
    
    header {  
        padding: ${(props)=>props.padding};
        p {
            color: ${(props)=>props.pFontColor} !important;
        }
        i {
            color: ${(props)=>props.iFontColor} !important;
        }
    }

`;


const HeaderLogoWrapper = styled.div`
float: left;
img {  
    width: ${(props)=>props.width};
   
}

`;

// This function will be triggered when the mouse pointer is over the box
var boxMouseOverHandler = function (event, color) {
  var box = event.currentTarget;
  box.style.backgroundColor = color;
};
// This function will be triggered when the mouse pointer is moving out the box
var boxMouseOutHandler = function (event, color) {
  var box = event.currentTarget;
  box.style.backgroundColor = color;
};

const socialLinkBackground = (color) =>{

    return {
        backgroundColor: `${color}`,
        width: '30px',
        height: '30px',
        borderRadius: '5px',
        position: 'relative'
    }
}

const callToActionStyle = (backgroundColor, color) =>{

  return {
      backgroundColor: `${backgroundColor}`,
      color:  `${color}`,
  }
}


const stickyHeaderStyle = (background_color) =>{

  return {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000,
    backgroundColor: background_color ? background_color : variables.initial_header_BackgroundColor,
    marginTop: '-10px',
    paddingTop: '10px',
    transition: '0.5s', /* Add a transition effect (when scrolling - and font size is decreased) */
  }
}
const standardHeaderStyle = () =>{

  return {
    position: 'relative',
  }
}

function FillTopHeaderSettingsObject(topHeaderData,headerData){

    if(topHeaderData != undefined)
    {
        var topHeaderSettings = {};
        topHeaderSettings.show_top_header = topHeaderData.show_top_header;
        topHeaderSettings.background_color = topHeaderData.background_color;
        topHeaderSettings.text = topHeaderData.text;
        topHeaderSettings.padding = topHeaderData.padding;
        topHeaderSettings.show_call_to_action = topHeaderData.show_call_to_action;
        topHeaderSettings.text_color = topHeaderData.text_color;
        topHeaderSettings.call_to_action_url = topHeaderData.call_to_action_url;
        topHeaderSettings.call_to_action_text = topHeaderData.call_to_action_text;
        topHeaderSettings.call_to_action_target = topHeaderData.call_to_action_target;
        topHeaderSettings.call_to_action_text_color = topHeaderData.call_to_action_text_color;
        topHeaderSettings.call_to_action_background_color = topHeaderData.call_to_action_background_color;

        topHeaderSettings.social_media = {};
        topHeaderSettings.social_media.show_facebook_icon = headerData.social_media.show_facebook_icon;
        topHeaderSettings.social_media.url_facebook = headerData.social_media.url_facebook;
        topHeaderSettings.social_media.facebook_icon_background_color = headerData.social_media.facebook_icon_background_color;
        topHeaderSettings.social_media.facebook_icon_hover_background_color = headerData.social_media.facebook_icon_hover_background_color;
        //Instagram
        topHeaderSettings.social_media.show_instagram_icon = headerData.social_media.show_instagram_icon;
        topHeaderSettings.social_media.url_instagram = headerData.social_media.url_instagram;
        topHeaderSettings.social_media.instagram_icon_background_color = headerData.social_media.instagram_icon_background_color;
        topHeaderSettings.social_media.instagram_icon_hover_background_color = headerData.social_media.instagram_icon_hover_background_color;

        return topHeaderSettings;
    }

    var topHeaderSettings = {};
    topHeaderSettings.show_top_header = true;
    topHeaderSettings.background_color = 'rgb(193, 150, 196)';
    topHeaderSettings.social_media = {};
    topHeaderSettings.social_media.show_facebook_icon = true;
    topHeaderSettings.padding = '18px';



    return topHeaderSettings;
}

function FillHeaderSettingsObject(headerData){

    if(headerData != undefined)
    {
        var headerSettings = {};
        headerSettings.social_media = {};
        headerSettings.social_media.show_facebook_icon = true;




        headerSettings.logo = headerData.logo;
        headerSettings.logo_title = headerData.logo_title;
        headerSettings.logo_alt = headerData.logo_alt;
        headerSettings.background_color = 'white';
        headerSettings.sticky = false;
        //debugger;
        return headerSettings;
    }
    else
    {
        var headerSettings = {};
        headerSettings.background_color = 'yellow';
        return headerSettings;
    }
   
}

function Header (props){
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()    
    const [isDesktop, setDesktop] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [usingStaticData, setUsingStaticData] = useState(false); 
    
    const [headerSettings, setHeaderSettings] = useState(true);
    const [topHeaderSettings, setTopHeaderSettings] = useState(true);

    const websiteSettings = useSelector((state) => state.websiteSettings.settings)
    //const headerSettings = websiteSettings?.Header;    
    
    //const topHeaderSettings = FillTopHeaderSettingsObject(websiteSettings?.TopHeader);
    const websiteSettingsStatus = useSelector(state => state.websiteSettings.status)



    

    useEffect(() => {
        dispatch(fetchWebsiteSettings(i18n.language));
    }, [dispatch,i18n.language])

    useEffect(() => {
        if (websiteSettingsStatus === 'idle') {
          dispatch(fetchWebsiteSettings(i18n.language))          
        }
        else if (websiteSettingsStatus === 'succeeded')
        {  
            setIsLoading(false);
            setHeaderSettings(FillHeaderSettingsObject(websiteSettings?.Header));
            //debugger;
            setTopHeaderSettings(FillTopHeaderSettingsObject(websiteSettings?.TopHeader,websiteSettings?.Header));
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [websiteSettingsStatus, dispatch])

      useEffect(() => {
        if (window.innerWidth > 650) {
          setDesktop(true);
        } else {
          setDesktop(false);
        }
    
        const updateMedia = () => {
          if (window.innerWidth > 650) {
            setDesktop(true);
          } else {
            setDesktop(false);
          }
        };
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
      }, []);



    if (isLoading===true) return null;

    return(
       
    <React.Fragment>       
        {
            isLoading === false ?   
        <>       

        { topHeaderSettings.show_top_header === true && isDesktop === true ?
         <TopHeaderWrapper
         backgroundColor={topHeaderSettings.background_color}
         textColor={topHeaderSettings.text_color}
         padding={topHeaderSettings.padding}
         position="relative"
         >
                 <Container fluid>
                     <Row>                        
                         <Col sm="4">
                         <ul className={styles["footer-social-list"]}>
                                        { topHeaderSettings.social_media.show_facebook_icon === true ? 
                                        <li className={styles["footer-social-listitem"]}>
                                            <div className={styles.socialLinkOuter} 
                                            style={socialLinkBackground(topHeaderSettings.social_media.facebook_icon_background_color)}
                                            onMouseOver={(e)=>boxMouseOverHandler(e,topHeaderSettings.social_media.facebook_icon_hover_background_color ? topHeaderSettings.social_media.facebook_icon_hover_background_color : variables.initial_socialLinkBgHoveringColor)}
                                            onMouseOut={(e)=>boxMouseOutHandler(e,topHeaderSettings.social_media.facebook_icon_background_color)}
                                            >   
                                                <a 
                                                href={`${topHeaderSettings.social_media.url_facebook}`}
                                                aria-label="External Link to Facebook"
                                                alt="External Link to Facebook"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles["socialLinkInner"]} 
                                                
                                                >
                                                    <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
                                                </a>
                                            </div>                                     
                                        </li>
                                        : ' ' }
                                        { topHeaderSettings.social_media.show_instagram_icon === true ?                                
                                        <li className={styles["footer-social-listitem"]}>
                                        <div className={styles.socialLinkOuter}
                                            style={socialLinkBackground(topHeaderSettings.social_media.instagram_icon_background_color)}
                                            onMouseOver={(e)=>boxMouseOverHandler(e,topHeaderSettings.social_media.instagram_icon_hover_background_color ? topHeaderSettings.social_media.instagram_icon_hover_background_color : variables.initial_socialLinkBgHoveringColor)}
                                            onMouseOut={(e)=>boxMouseOutHandler(e,topHeaderSettings.social_media.instagram_icon_background_color)}
                                            >                                    
                                                <a 
                                                href={ `${topHeaderSettings.social_media.url_instagram}`}
                                                aria-label="External Link to Instagram"
                                                alt="External Link to Instagram"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles["socialLinkInner"]}>
                                                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                                </a>
                                            </div>
                                        </li>
                                        : '' }
                                                                    
                                    </ul>
                         </Col>
                         <Col sm="8">
                          <div style={{
                                  right: "1rem",
                                  left: "auto",
                                  position: "absolute", lineHeight: "30px"
                              }}>
                            <div style={{float:"left"}}>
                              {topHeaderSettings.text}
                            </div>
                            {
                              topHeaderSettings.show_call_to_action === true ?
                              <div style={{float:"left"}}>
                              <a className={styles["site-button"]} 
                              style={callToActionStyle(topHeaderSettings.call_to_action_background_color,
                                topHeaderSettings.call_to_action_text_color)} 
                              target={`${topHeaderSettings.call_to_action_target}`}
                              href={`${topHeaderSettings.call_to_action_url}`}>
                              <i className="fa fa-shopping-cart"></i>{`${topHeaderSettings.call_to_action_text}`}</a>
                            </div>
                            :''
                            }
                            </div>
                         </Col>
                     </Row>
                 </Container>
             </TopHeaderWrapper>
            
             : <></>
             
             } 
        <HeaderWrapper
        backgroundImage={headerSettings.show_background_image === true ? `${process.env.NEXT_PUBLIC_BACKEND_API_URL}${headerSettings.background_image?.data?.attributes.url}`:''}
        backgroundColor={headerSettings.background_color}        
        padding={headerSettings.padding}
        style={headerSettings.sticky === true ? { marginBottom: '100px' } : { marginBottom: '0px' } } 
        >
        <header className={styles.header}  style={headerSettings.sticky === false ? standardHeaderStyle() : stickyHeaderStyle(headerSettings.background_color)}>
            <Container>
                <Row>
                    <Col> 
                    {/* <ul className={styles["footer-social-list"]}>
                                    <li className={styles["footer-social-listitem"]}>
                                        <div className={styles.socialLinkOuter} 
                                        style={socialLinkBackground(headerSettings.sm_icon_fb_background_color)}
                                        onMouseOver={(e)=>boxMouseOverHandler(e,variables.initial_socialLinkBgHoveringColor)}
                                          onMouseOut={(e)=>boxMouseOutHandler(e,headerSettings.sm_icon_fb_background_color)}
                                        >   
                                            <a href={`${headerSettings.sm_url_facebook}`} target="_blank" rel="noopener noreferrer" className={styles["socialLinkInner"]}>
                                                <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
                                            </a>
                                        </div>                                     
                                    </li>
                                    { headerSettings.sm_url_instagram ?                                
                                    <li className={styles["footer-social-listitem"]}>
                                       <div className={styles.socialLinkOuter}
                                          style={socialLinkBackground(headerSettings.sm_icon_instagram_background_color)}
                                          onMouseOver={(e)=>boxMouseOverHandler(e,variables.initial_socialLinkBgHoveringColor)}
                                          onMouseOut={(e)=>boxMouseOutHandler(e,headerSettings.sm_icon_instagram_background_color)}
                                          >                                    
                                            <a 
                                            href={ `${headerSettings.sm_url_instagram}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={styles["socialLinkInner"]}>
                                                <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                            </a>
                                        </div>
                                    </li>
                                    : '' }                                  
                                </ul> */}
                    </Col>
                </Row>  
                <Row>
                    {/* <Col>                    
                        <nav>
                            <HeaderLogoWrapper
                            width="150px">
                              <Link className="m-r-auto" href="/" passHref={true}>
                                <a>
                                  <Image width="180" height="80" alt={`${headerSettings.logo_alt}`} src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${headerSettings.logo.data.attributes.url}`} />
                                </a>
                              </Link>
                            </HeaderLogoWrapper>  
                        </nav>
                       
                    </Col> */}
                    <Col>                    
                    <Navigation 
                    brandLogoUrl={ usingStaticData===false ? 
                    headerSettings.logo.data ? `${ process.env.NEXT_PUBLIC_BACKEND_API_URL}${headerSettings.logo.data.attributes.url}`
                    :`${ process.env.NEXT_PUBLIC_WEBSITE}${staticConfiguration.logo.data.attributes.url}` 
                    : `${headerSettings.logo.data.attributes.url}`}
                    brandLogoAlt={`${headerSettings.logo_alt}`}
                    brandLogoTitle={`${headerSettings.logo_title}`}
                    /> 
                  
                    </Col>
                      
                </Row>   
                                    
                              
            </Container>
        </header>
        </HeaderWrapper>

        </>
        :' waiting for header'
    }
    </React.Fragment>
    
)
}


export default Header;
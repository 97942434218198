import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import user from './userSlice';
import counter from './countersSlice';
import websiteSettings from './websiteSettingsSlice';
import selectedLanguage from './currentlySelectedLanguageSlice';

const combinedReducer = combineReducers({
  counter,
  user,
  websiteSettings,
  selectedLanguage,
});

const masterReducer = (state, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            counter: {
                count: state.counter.count + action.payload.counter.count,
            },
            users: {
                users: [...action.payload.users.users, ...state.users.users]
            },
            websiteSettings: {
                settings: {...action.payload.websiteSettings.settings, ...state.websiteSettings.settings}
            },
            selectedLanguage: {
                 lang: state.selectedLanguage.language
            }
        }
        return nextState;
    } else {
    return combinedReducer(state, action)
  }
}

export const makeStore = () => {
  return configureStore({
    reducer: masterReducer,
  });
}

export const wrapper = createWrapper(makeStore, { debug: false });
import { useRef, useState, createRef,useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Image from 'next/image';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';


// Using Global Redux State
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../store/currentlySelectedLanguageSlice';


const languageOptions = {
  en: {
    icon: '/assets/icons/flags/uk_flag.svg',
    label: 'English'
  },
  de: {
    icon: '/assets/icons/flags/de_flag.svg',
    label: 'German'
  },
  // es: {
  //   icon: '/assets/icons/flags/es_flag.svg',
  //   label: 'Spanish'
  // }
};

const LanguagePopover = () => {
  const anchorRef = useRef(null);
  const dispatch = useDispatch()
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [reference, setReference] = useState({});


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);    
    setOpen(false);
    //Todo: Save selected value to global store
    localStorage.setItem('selectedLanguage', language);
  };

  useEffect(() => {       
    const newReference = createRef();
    setReference(newReference);    
  },[])
  let language = '';
  if (typeof window !== 'undefined') {
    // console.log('You are on the browser')
    language = localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'de';
    // 👉️ can use localStorage here
  } else {
    // console.log('You are on the server')
    // 👉️ can't use localStorage
  }

  const selectedOption = languageOptions[language];

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}       
      >
        <Box
          sx={{
            display: 'flex',
            height: 20,
            width: 20,
            '& img': {
              width: '100%'
            }
          }}
        >
          <Image
            height={`50px`}
            width={`50px`}
            alt={selectedOption.label}
            src={selectedOption.icon}
          />
        </Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  height: 20,
                  width: 20,
                  '& img': {
                    width: '100%'
                  }
                }}
              >               
                  <Image 
                      height={`50px`}
                      width={`50px`}
                    alt={languageOptions[language].label}
                    src={languageOptions[language].icon}
                  />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {languageOptions[language].label}
                </Typography>
              )}
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;

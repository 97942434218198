// import { createSlice } from '@reduxjs/toolkit'

// const initialState = {
//   users: []
// }

// export const usersSlice = createSlice({
//   name: 'users',
//   initialState,
//   reducers: {
//       addUser: (state, action) => {
//           state.users = [...state.users, action.payload]
//       }
//   }
// })

// export const { addUser } = usersSlice.actions

// export default usersSlice.reducer
import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('cookieBannerData');
    if (serializedState === null) {      
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {    
    return undefined;
  }
}; 

const persistedState = loadState();
const initialState = {
  settings: {},
  localStorage: persistedState,
  status: 'idle',
  error: null
}

// export const fetchUserSettings = createAsyncThunk('websiteSettings/fetchWebsiteSettings', async (selectedLanguage) => {
//   const response = await fetch(`/api/backend/user?locale=${selectedLanguage}`, {
//     headers: {
//       Accept: "application/json",
//     },
//   });
//   const settingsDataFromBackend = await response.json();
//   return settingsDataFromBackend
// })


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
      getLocalUserInfo: (state, action) => {          
          state.localStorage = { data: "stringus90" };
      },
      updateLocalStorage: (state, action) => {                  
        state.localStorage = action.payload;
    }
  },
  // extraReducers(builder) {
  //   builder
  //     .addCase(fetchUserSettings.pending, (state, action) => {
  //       state.status = 'loading'
  //     })
  //     .addCase(fetchUserSettings.fulfilled, (state, action) => {
  //       state.status = 'succeeded'
  //       state.settings = action.payload
  //     })
  //     .addCase(fetchUserSettings.rejected, (state, action) => {
  //       state.status = 'failed'
  //       state.error = action.error.message
  //     })
  // }
})

export const { getLocalUserInfo,updateLocalStorage } = userSlice.actions

export default userSlice.reducer
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { Container, Row, Col, Navbar, Nav, NavDropdown,Form,FormControl, Button,Offcanvas } from 'react-bootstrap';
//Load Styles
import styles from '../../styles/components/common/Navigation.module.scss'
// Using Global Redux State
import { useSelector, useDispatch } from 'react-redux';
//i18n - For disabling i18n option simply comment
import LanguagePopover from './LanguagePopover';
//i18n
import { useTranslation } from 'react-i18next';

function Navigation (props){
  const { t, i18n } = useTranslation();
  const [showFullNavigation, setShowFullNavigation] = useState(false);
  const [cookieBannerData, setCookieBannerData] = useState({});
  const dispatch = useDispatch()
  
  const localStorageRedux = useSelector((state) => {
   
    return state.user.localStorage
  }
  )
  

  useEffect(() => {
      if (typeof window !== 'undefined') {
       
        try {
          if(localStorageRedux?.accepted==="true")
          {       
           setShowFullNavigation(true)
          }
          // can use localStorage here
        } catch (error) {
          setShowFullNavigation(false)
        }
        
        } else {      
          // can't use localStorage
        }
      },[localStorageRedux])

  return(
     
  <React.Fragment>       
     
      <>        
      <Navbar collapseOnSelect expand="lg" className={styles.navbar}  sticky="top">
        <Container className={styles.navbarContainer}>
        <Navbar.Brand href="/">
            <Image
            className={styles.navbarLogo}
            width="250"
            height="100%"
            alt={`${props.brandLogoAlt}`} 
            title={`${props.brandLogoTitle}`}
            src={`${props.brandLogoUrl}`} />
        </Navbar.Brand>
        <Navbar.Toggle
        // aria-controls="responsive-navbar-nav"
        className={`order-md-1 order-0 ${styles.navbarHamburger}`}
        />
        <Navbar.Collapse id="responsive-navbar-nav" className="order-md-1 order-0">
          {showFullNavigation===true ?
          <>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
          {/* <Nav style={{ width: "100%" }}> */}
            <Nav.Link href="/" className={styles.navLink}>{t('navigation_link_home')}</Nav.Link>
            <Nav.Link href="/blog" className={styles.navLink}>{t('navigation_link_blog')}</Nav.Link>
            <Nav.Link href="/kurstermine" className={styles.navLink}>{t('navigation_link_course_dates')}</Nav.Link>
              <NavDropdown
              title={t('navigation_link_my_services')}
              className={`${styles.navDropdown} ${styles.noNavLink}`}
              id="basic-nav-dropdown-one">
              <NavDropdown.Item href="/schwangerschaft" className={styles.navDropdownItem}>{t('navigation_link_my_services_pregnancy')}</NavDropdown.Item>
              <NavDropdown.Item href="/geburt" className={styles.navDropdownItem}>{t('navigation_link_my_services_birth')}</NavDropdown.Item>
              <NavDropdown.Item href="/wochenbett" className={styles.navDropdownItem}>{t('navigation_link_my_services_weekbed')}</NavDropdown.Item>
              <NavDropdown.Item href="/kurse" className={styles.navDropdownItem}>{t('navigation_link_my_services_courses')}</NavDropdown.Item>
              {/* <NavDropdown.Item href="/k-taping" className={styles.navDropdownItem}>{t('navigation_link_my_services_taping')}</NavDropdown.Item>
              <NavDropdown.Item href="/akupunktur" className={styles.navDropdownItem}>{t('navigation_link_my_services_acupuncture')}</NavDropdown.Item> */}
              <NavDropdown.Item href="/preisliste" className={styles.navDropdownItem}>{t('navigation_link_my_services_costs')}</NavDropdown.Item>
              {/* <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4" className={styles.navDropdownItem}>Separated link</NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link 
            className={styles.navLink}
            href="/uebermich">{t('navigation_link_about')}</Nav.Link>             
            <NavDropdown title={t('navigation_link_contact')} className={styles.navDropdown} id="basic-nav-dropdown-two">
              <NavDropdown.Item href="/contact" className={styles.navDropdownItem}>{t('navigation_link_contact')}</NavDropdown.Item>
              <NavDropdown.Item 
              target="_blank"
              href="https://schelander.hebamio.at/anmeldung"
              className={styles.navDropdownItem}>{t('navigation_link_making_an_appointment')}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/legal/imprint" className={styles.navDropdownItem}>{t('navigation_link_imprint')}</NavDropdown.Item>
              <NavDropdown.Item href="/legal/dataprotection" className={styles.navDropdownItem}>{t('navigation_link_dataprotection')}</NavDropdown.Item>
              <NavDropdown.Item href="/legal/agb" className={styles.navDropdownItem}>{t('navigation_link_agb')}</NavDropdown.Item>
              {/* <NavDropdown.Item href="/legal/treatmentcontract" className={styles.navDropdownItem}>Behandlungsvertrag</NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
          <LanguagePopover/>
          </>
          :
            <LanguagePopover/>
          }
        </Navbar.Collapse>
        
        </Container>
      </Navbar>

      </>
     
  
  </React.Fragment>
  
)
}


export default Navigation;
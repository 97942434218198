import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'

const initialState = {
  settings: {},
  status: 'idle',
  error: null
}

export const fetchWebsiteSettings = createAsyncThunk('websiteSettings/fetchWebsiteSettings', async (selectedLanguage) => {
  console.log("Calling Settings Endpoint");
  const response = await fetch(`/api/backend/settings?locale=${selectedLanguage}`, {
    headers: {
      Accept: "application/json",
    },
  });
  const settingsDataFromBackend = await response.json();
  return settingsDataFromBackend;
})


export const websiteSettingsSlice = createSlice({
  name: 'websiteSettings',
  initialState,
  reducers: {
    // getWebsiteSettings: (state, action) => {          
    //       state.settings = { data: "stringus90" };
    //   }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWebsiteSettings.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchWebsiteSettings.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        // state.websiteSettings = state.websiteSettings.concat(action.payload)
        state.settings = action.payload
      })
      .addCase(fetchWebsiteSettings.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export const { getWebsiteSettings } = websiteSettingsSlice.actions

export default websiteSettingsSlice.reducer
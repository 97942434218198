import '../styles/globals.scss'
import NextNProgress from "nextjs-progressbar";
import Router from 'next/router';
import NProgress from 'nprogress'; //nprogress module
import 'nprogress/nprogress.css'; //styles of nprogress
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram, faFacebook,faFacebookF,faPinterest,faTiktok,faSpotify,faYoutube,faWhatsapp } from '@fortawesome/free-brands-svg-icons'
library.add(fas, faTwitter, faInstagram, faFacebook,faFacebookF,faPinterest,faEnvelope,faLink,faTiktok,faSpotify,faYoutube,faWhatsapp)
// import i18n (needs to be bundled ;)) 
import '../i18n/i18n';
import { wrapper } from "../store/";
import Maintainance from '../components/common/Maintainance';

function MyApp({ Component, pageProps }) {
  if (process.env.NEXT_PUBLIC_MAINTAINANCE_MODE==="1") {
    return <Maintainance />;
  }
  return (
  <>
    <Component {...pageProps} />
  </>
  )
}
export default wrapper.withRedux(MyApp);
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    language: 'de',
}

export const currentlySelectedLanguageSlice = createSlice({
    name: "selectedLanguage",
    initialState,
    reducers: {
      setLanguage: (state, action) => {
            state.language = action.payload
        }
    }
})

export const { setLanguage } = currentlySelectedLanguageSlice.actions

export default currentlySelectedLanguageSlice.reducer